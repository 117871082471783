import React from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Intro from "../../../components/intro/index";
import HomeData from "../../../data/home.json";

const IntroContainer = () => {
    SwiperCore.use([Pagination]);

    const settings = {
        loop: true,
        speed: 750,
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: {
            clickable: true,
        },
    };
    return (
        <div className="intro-slider-wrap">
            <Swiper className="intro-slider" {...settings}>
                {HomeData[0].slider &&
                    HomeData[0].slider.map((single, key) => (
                        <SwiperSlide key={key}>
                            <Intro data={single} key={key} />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

export default IntroContainer;
